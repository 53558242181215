import { Title } from '@solidjs/meta';
import { Link, Section, Heading, Button } from '@troon/ui';

export default function NotFound() {
	const params = new URLSearchParams({
		width: '480',
		height: '300',
		format: 'webp',
		mode: 'crop',
		crop: 'entropy',
	}).toString();

	return (
		<div class="flex h-full items-center justify-center">
			<Title>404 not found | Troon</Title>
			<Section
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class="min-h-[50svh] items-center justify-center text-center"
			>
				<Heading as="h1" class="flex flex-col">
					<span
						class="bg-cover bg-clip-text bg-center font-bold leading-none text-transparent"
						style={{
							'background-image': `url(${window.__CONFIG__.imageHost}/web/hero/404.png?${params})`,
							'font-size': 'clamp(min(3vw, 10rem), 18vw, 18rem)',
						}}
					>
						404
					</span>
					Page Not Found
				</Heading>

				<p>We are sorry, but we can’t seem to find the page you are looking for.</p>

				<Button as={Link} href="/" class="size-fit shrink grow-0">
					Return home
				</Button>
			</Section>
		</div>
	);
}
